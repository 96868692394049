<template>
  <div class="vld-parent">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :on-cancel="onCancel"
      :is-full-page="false"
      :color="'#5fa2dd'"
    ></loading>

    <div class="cart">
      <h1 class="moby-font-family">Filtro</h1>
      <div class="search">
        
        <div class="select">
          <multiselect
            v-model="selectedProject"
            :options="projects"
            :multiple="true"
            group-values="projectsInfo"
            group-label="projectGroup"
            :group-select="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Seleccione un proyecto"
            label="nombreProyecto"
            track-by="nombreProyecto"
            :preselect-first="false"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }"
              ><span
                class="multiselect__single"
                v-if="values.length &amp;&amp; !isOpen"
                >{{ values.length }} opciones seleccionadas</span
              ></template
            >
          </multiselect>
        </div>

        <h2>Rango de fechas</h2>

        <div class="select">
          <input v-model="fechaInicio" type="date" class="dates" />
        </div>

        <div class="select">
          <input v-model="fechaFin" type="date" class="dates" />
        </div>
      </div>

      <button @click="buscar" class="moby-bg">Buscar</button>
      <button @click="limpiar" class="moby-bg">Limpiar</button>
    </div>



    <div class="cart">
      <h1 class="moby-font-family">Top</h1>
      <div class="modydash-cont-marketing">

        <div>
          <div class="mobydash-col">
            <highcharts :options="chartOptions.origenUTM"></highcharts>
          </div>
        </div>

        <div>
          <div class="mobydash-col">
            <highcharts :options="chartOptions.campanhaUTM" ></highcharts>
          </div>
        </div>

        <div class="div-table">
          <div class="modydash-text1"><span>Top 10 Orígenes</span></div>
          <div class="mobydash-col">
            <table class="table-origenes-campanhas">
              <thead>
                <tr>
                  <th class="table-origenes-campanhas-header">NOMBRE</th>
                  <th class="table-origenes-campanhas-header">CANTIDAD</th>
                  <th class="table-origenes-campanhas-header">PORCENTAJE</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="origenUTM in origenesUTM" v-bind:key="origenUTM.nombre">
                  <td>{{origenUTM.nombre}}</td>
                  <td class="medioCantidad">{{origenUTM.cantidad}}</td>
                  <td class="medioPorcentaje">{{origenUTM.porcentaje}} %</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="div-table">
          <div class="modydash-text1"><span>Top 10 Campañas</span></div>
          <div class="mobydash-col">
            <table class="table-origenes-campanhas">
              <thead>
                <tr>
                  <th class="table-origenes-campanhas-header">NOMBRE</th>
                  <th class="table-origenes-campanhas-header">CANTIDAD</th>
                  <th class="table-origenes-campanhas-header">PORCENTAJE</th>
                </tr>
              </thead>
              <tbody>                
                <tr v-for="campanhaUTM in campanhasUTM" v-bind:key="campanhaUTM.nombre">
                  <td>{{campanhaUTM.nombre}}</td>
                  <td class="medioCantidad">{{campanhaUTM.cantidad}}</td>
                  <td class="medioPorcentaje">{{campanhaUTM.porcentaje}} %</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import axios from "axios";
import Multiselect from "vue-multiselect";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

let dataValues = {
  idProyecto: [],
  fechaInicio: "",
  fechaFin: "",
  tipoDato: "",
};

export default {
  name: "MobyDashboard",
  components: {
    highcharts: Chart,
    Multiselect,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      origenesUTM: [],
      campanhasUTM: [],
      selectedProject: null,
      fechaInicio: null,
      fechaFin: null,
      users: [],
      projects: [
        {
          projectGroup: "Seleccionar/Deseleccionar todos",
          projectsInfo: [],
        },
      ],
      chartOptions: {
        origenUTM: {
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
          },
          title: {
          text: 'Orígenes'
          },
          tooltip: {
            pointFormat: '<b>{point.percentage:.1f}%</b>'
          },
          accessibility: {
            point: {
              valueSuffix: '%'
            }
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: true,
                  pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>'
              },
              showInLegend: true
            }
          },
          series: [{
            name: 'Medio',
            colorByPoint: true,
            data: []
          }]
        },
        campanhaUTM: {
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
          },
          title: {
          text: 'Campañas'
          },
          tooltip: {
            pointFormat: '<b>{point.percentage:.1f}%</b>'
          },
          accessibility: {
            point: {
              valueSuffix: '%'
            }
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: true,
                  pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>'
              },
              showInLegend: true
            }
          },
          series: [{
            name: 'Medio',
            colorByPoint: true,
            data: []
          }]
        }
      }, 
    };
  },
  props: {
    url: String,
    tokenUser: {String}
  },
  methods: {
    buscar() {
      dataValues.idProyecto = [];
      dataValues.fechaInicio = this.fechaInicio;
      dataValues.fechaFin = this.fechaFin;
      if (
        dataValues.idProyecto == null ||
        dataValues.fechaInicio == null ||
        dataValues.fechaFin == null ||
        this.selectedProject == null ||
        this.selectedProject.length == 0
      ) {
        alert("Favor de llenar todos los campos");
        return;
      }
      var d1 = Date.parse(dataValues.fechaInicio);
      var d2 = Date.parse(dataValues.fechaFin);
      if (d1 > d2) {
        alert("Error! Las fechas no son Correctas");
        return;
      }

      for (var project of this.selectedProject) {
        dataValues.idProyecto.push(project.id);
      }

      this.isLoading = true;
      
      // Cargar orígenes
      dataValues.tipoDato = "ORIGENES_UTM";
      axios
      .post(
        this.url + "mobysuite/dashboard?" + ((this.tokenUser == null || this.tokenUser == "") ? "uName=web&uPass=196f3c13338147a9dafacd5646fa0986" :
          "uToken="+this.tokenUser) + "&graph=3",dataValues
      )
      .then((response) => {
        var data = [];
        var dataOrigenes = [];
        var total = 0;
        for (var i = 0; i < response.data.length; i += 1) {
          data.push({name: response.data[i].nombre.toUpperCase(), y: response.data[i].cantidad});
          if(i < 10) {
            dataOrigenes.push({nombre: response.data[i].nombre.toUpperCase(), cantidad: response.data[i].cantidad, porcentaje: 0.0});
          }          

          total += response.data[i].cantidad;
        }
        // Datos para el gráfico
        this.chartOptions.origenUTM.series[0].data = data;
        
        for(let i = 0; i < dataOrigenes.length; i++){
          dataOrigenes[i].porcentaje = (dataOrigenes[i].cantidad / total * 100).toFixed(2);
        }
        this.origenesUTM = dataOrigenes;


    })
      .catch((error) => {
        console.log(error)
        this.isLoading = false;
    })

    

    /********************************************************************************************************* */
    dataValues.tipoDato = "CAMPANHAS_UTM";
    axios
      .post(
        this.url + "mobysuite/dashboard?" + ((this.tokenUser == null || this.tokenUser == "") ? "uName=web&uPass=196f3c13338147a9dafacd5646fa0986" :
          "uToken="+this.tokenUser) + "&graph=3",dataValues
      )
      .then((response) => {
        var data = [];
        var dataCamp = [];
        var total = 0;
        for (var i = 0; i < response.data.length; i += 1) {
          data.push({name: response.data[i].nombre.toUpperCase(), y: response.data[i].cantidad});
          if(i < 10) {
            dataCamp.push({nombre: response.data[i].nombre.toUpperCase(), cantidad: response.data[i].cantidad, porcentaje: 0.0});
          } 
          total += response.data[i].cantidad;
        }
        this.chartOptions.campanhaUTM.series[0].data = data;

        for(let i = 0; i < dataCamp.length; i++){
          dataCamp[i].porcentaje = (dataCamp[i].cantidad / total * 100).toFixed(2);
        }
        this.campanhasUTM = dataCamp;

        this.isLoading = false;
    })
      .catch((error) => {
        console.log(error)
        this.isLoading = false;
    })

    },
    limpiar() {
      this.selectedProject = null;
      this.fechaInicio = null;
      this.fechaFin = null;
      this.chartOptions.origenUTM.series[0].data = [];
      this.chartOptions.campanhaUTM.series[0].data = [];
      this.origenesUTM = [];
      this.campanhasUTM = [];
    },
    onCancel() {
      console.log("User cancelled the loader.");
    },
  },
  mounted() {
    // Cargar fechas de prueba...
    // this.fechaInicio = '2010-07-21';
    // this.fechaFin = '2022-07-21';

    axios
      .get(
        this.url + "mobysuite/listProjects?" + ((this.tokenUser == null || this.tokenUser == "") ? "uName=web&uPass=196f3c13338147a9dafacd5646fa0986" :
          "uToken="+this.tokenUser) + "&dashboard_widget=true"
      )
      .then((response) => (this.projects[0].projectsInfo = response.data))
      .catch((error) => console.log(error));
  },
};

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
@import '../../assets/main.css';
</style>
