<template>
  <div class="vld-parent">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :on-cancel="onCancel"
      :is-full-page="false"
      :color="'#5fa2dd'"
    ></loading>

    <div class="cart">
      <h1 class="moby-font-family">Filtro</h1>
      <div class="search">
        <div class="select">
          <select v-model="selectedUser">
            <option disabled value="null">Seleccione usuario</option>
            <option v-for="user in users" v-bind:key="user.id" :value="user.id">
              {{ user.nombreCompleto }}
            </option>
          </select>
        </div>

        <div class="select">
          <multiselect
            v-model="selectedProject"
            :options="projects"
            :multiple="true"
            group-values="projectsInfo"
            group-label="projectGroup"
            :group-select="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Seleccione un proyecto"
            label="nombreProyecto"
            track-by="nombreProyecto"
            :preselect-first="false"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }"
              ><span
                class="multiselect__single"
                v-if="values.length &amp;&amp; !isOpen"
                >{{ values.length }} opciones seleccionadas</span
              ></template
            >
          </multiselect>
        </div>

        <h2>Rango de fechas</h2>

        <div class="select">
          <input v-model="fechaInicio" type="date" class="dates" />
        </div>

        <div class="select">
          <input v-model="fechaFin" type="date" class="dates" />
        </div>
      </div>

      <button @click="buscar" class="moby-bg">Buscar</button>
      <button @click="limpiar" class="moby-bg">Limpiar</button>
    </div>

    <div class="cart">
      <h1 class="moby-font-family">Gráficos</h1>
      <div class="modydash-cont">
        <div>
          <div class="mobydash-col">
            <highcharts :options="chartOptions.chartEjecutivo"></highcharts>
          </div>
          <div class="modydash-footer-container">
            <div class="modydash-text1">Actividades realizadas</div>
            <div class="modydash-text2">Actividades pendientes</div>
          </div>
        </div>

        <div>
          <div class="mobydash-col">
            <highcharts
              :options="chartOptions.chartTodosEjecutivos"
            ></highcharts>
          </div>
          <div class="modydash-footer-container">
            <div class="modydash-text1">Actividades realizadas</div>
            <div class="modydash-text2">Actividades pendientes</div>
          </div>
        </div>
        <div>
          <div class="mobydash-col">
            <highcharts :options="chartOptions.chartCompAbsoluto"></highcharts>
          </div>
          <div class="modydash-footer-container">
            <div class="modydash-text1">Actividades realizadas</div>
            <div class="modydash-text2">Actividades pendientes</div>
          </div>
        </div>
        <div>
          <div class="mobydash-col">
            <highcharts
              :options="chartOptions.chartCompPorcentaje"
            ></highcharts>
          </div>
          <div class="modydash-footer-container">
            <div class="modydash-text1">Actividades realizadas</div>
            <div class="modydash-text2">Actividades pendientes</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import axios from "axios";
import Multiselect from "vue-multiselect";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

let dataValues = {
  idUsuario: 0,
  idProyecto: [],
  fechaInicio: "",
  fechaFin: "",
};

export default {
  name: "MobyDashboard",
  components: {
    highcharts: Chart,
    Multiselect,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      selectedUser: null,
      selectedProject: null,
      fechaInicio: null,
      fechaFin: null,
      users: [],
      projects: [
        {
          projectGroup: "Seleccionar/Deseleccionar todos",
          projectsInfo: [],
        },
      ],
      absfTiempo: [0, 0],
      abseTiempo: [0, 0],
      absAtrasado: [0, 0],
      absaDia: [0, 0],
      chartOptions: {
        chartEjecutivo: {
          legend: {
            alignColumns: false,
          },
          chart: {
            //margin: [0, 0, 0, 0],
            type: "column",
          },
          title: {
            text: "ESTADO DE GESTIONES DEL VENDEDOR",
          },
          xAxis: {
            categories: [
              "NO GESTIONADO",
              "NO EFECTIVAS",
              "EN PROCESO",
              "CERRADO",
            ],
            max: 3,
          },
          yAxis: {
            min: 0,
            title: {
              text: "CANTIDAD",
            },
            stackLabels: {
              enabled: true,
              style: {
                fontWeight: "bold",
                color: "gray",
              },
            },
          },
          tooltip: {
            headerFormat: "<b>{point.x}</b><br/>",
            pointFormat:
              "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
          },
          plotOptions: {
            column: {
              stacking: "normal",
              dataLabels: {
                enabled: true,
              },
            },
          },
          series: [
            {
              name: "Fuera de tiempo",
              color: "#C5E0B4",
              data: [0, 0, 0],
            },
            {
              name: "En tiempo",
              color: "#70AD47",
              data: [0, 0, 0],
            },
            {
              name: "Atrasadas",
              color: "Red",
              data: [0, 0, 0],
            },
            {
              name: "Al día",
              color: "#F4B183",
              data: [0, 0, 0],
            },
          ],
        },
        chartTodosEjecutivos: {
          legend: {
            alignColumns: false,
          },
          chart: {
            type: "column",
          },
          title: {
            text: "ESTADO DE GESTIONES DE TODO EL EQUIPO DE VENTAS",
          },
          xAxis: {
            categories: [
              "NO GESTIONADO",
              "NO EFECTIVAS",
              "EN PROCESO",
              "CERRADO",
            ],
            max: 3,
          },
          yAxis: {
            min: 0,
            title: {
              text: "CANTIDAD",
            },
            stackLabels: {
              enabled: true,
              style: {
                fontWeight: "bold",
                color: "gray",
              },
            },
          },
          tooltip: {
            headerFormat: "<b>{point.x}</b><br/>",
            pointFormat:
              "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
          },
          plotOptions: {
            column: {
              stacking: "normal",
              dataLabels: {
                enabled: true,
              },
            },
          },
          series: [
            {
              name: "Fuera de tiempo",
              color: "#C5E0B4",
              data: [0, 0, 0],
            },
            {
              name: "En tiempo",
              color: "#70AD47",
              data: [0, 0, 0],
            },
            {
              name: "Atrasadas",
              color: "Red",
              data: [0, 0, 0],
            },
            {
              name: "Al día",
              color: "#F4B183",
              data: [0, 0, 0],
            },
          ],
        },
        chartCompAbsoluto: {
          legend: {
            alignColumns: false,
          },
          chart: {
            type: "column",
          },
          title: {
            text: "Comparación en n° absoluto de productividad de vendedor v/s equipo",
          },
          xAxis: {
            categories: ["VENDEDOR X", "EQUIPO COMPLETO"],
          },
          yAxis: {
            min: 0,
            title: {
              text: "CANTIDAD",
            },
            stackLabels: {
              enabled: true,
              style: {
                fontWeight: "bold",
                color: "gray",
              },
            },
          },
          tooltip: {
            headerFormat: "<b>{point.x}</b><br/>",
            pointFormat:
              "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
          },
          plotOptions: {
            column: {
              stacking: "normal",
              dataLabels: {
                enabled: true,
              },
            },
          },
          series: [
            {
              name: "Fuera de tiempo",
              color: "#C5E0B4",
              data: [0, 0],
            },
            {
              name: "En tiempo",
              color: "#70AD47",
              data: [0, 0],
            },
            {
              name: "Atrasadas",
              color: "Red",
              data: [0, 0],
            },
            {
              name: "Al día",
              color: "#F4B183",
              data: [0, 0],
            },
          ],
        },
        chartCompPorcentaje: {
          legend: {
            alignColumns: false,
          },
          chart: {
            type: "column",
          },
          title: {
            text: "Comparación porcentual de productividad de vendedor v/s equipo de ventas",
          },
          xAxis: {
            categories: ["VENDEDOR X", "EQUIPO COMPLETO"],
          },
          yAxis: {
            min: 0,
            title: {
              text: "CANTIDAD",
            },
          },
          tooltip: {
            pointFormat:
              '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
            shared: true,
          },
          plotOptions: {
            column: {
              stacking: "percent",
            },
          },
          series: [
            {
              name: "Fuera de tiempo",
              color: "#C5E0B4",
              data: [0, 0],
            },
            {
              name: "En tiempo",
              color: "#70AD47",
              data: [0, 0],
            },
            {
              name: "Atrasadas",
              color: "Red",
              data: [0, 0],
            },
            {
              name: "Al día",
              color: "#F4B183",
              data: [0, 0],
            },
          ],
        },
      },
    };
  },
  props: {
    url: {String},
    tokenUser: {String}
  },
  methods: {
    buscar() {
      dataValues.idUsuario = this.selectedUser;
      dataValues.idProyecto = [];
      dataValues.fechaInicio = this.fechaInicio;
      dataValues.fechaFin = this.fechaFin;
      if (
        dataValues.idUsuario == null ||
        dataValues.idProyecto == null ||
        dataValues.fechaInicio == null ||
        dataValues.fechaFin == null
      ) {
        alert("Favor de llenar todos los campos");
        return;
      }
      var d1 = Date.parse(dataValues.fechaInicio);
      var d2 = Date.parse(dataValues.fechaFin);
      if (d1 > d2) {
        alert("Error! Las fechas no son Correctas");
        return;
      }

      for (var project of this.selectedProject) {
        dataValues.idProyecto.push(project.id);
      }

      this.isLoading = true;
      axios
        .post(
          this.url + "mobysuite/dashboard?" + ((this.tokenUser == null || this.tokenUser == "") ? "uName=web&uPass=196f3c13338147a9dafacd5646fa0986" :
          "uToken="+this.tokenUser) + "&graph=1",
          dataValues
        )
        .then((response) => {
          var fTiempo = [0, 0, 0],
            eTiempo = [0, 0, 0],
            atrasado = [0, 0, 0],
            adia = [0, 0, 0];
          this.absfTiempo = [0, 0];
          this.abseTiempo = [0, 0];
          this.absAtrasado = [0, 0];
          this.absaDia = [0, 0];
          for (var i = 0; i < response.data.length; i += 1) {
            if (response.data[i].descripcion == "NO GESTIONADO") {
              fTiempo[0] = response.data[i].fueraTiempo;
              eTiempo[0] = response.data[i].enTiempo;
              atrasado[0] = response.data[i].atrasadas;
              adia[0] = response.data[i].alDia;
            }
            if (response.data[i].descripcion == "NO EFECTIVOS") {
              fTiempo[1] = response.data[i].fueraTiempo;
              eTiempo[1] = response.data[i].enTiempo;
              atrasado[1] = response.data[i].atrasadas;
              adia[1] = response.data[i].alDia;
            }
            if (response.data[i].descripcion == "EN PROCESO") {
              fTiempo[2] = response.data[i].fueraTiempo;
              eTiempo[2] = response.data[i].enTiempo;
              atrasado[2] = response.data[i].atrasadas;
              adia[2] = response.data[i].alDia;
            }
            if (response.data[i].descripcion == "CERRADO") {
              fTiempo[3] = response.data[i].fueraTiempo;
              eTiempo[3] = response.data[i].enTiempo;
              atrasado[3] = response.data[i].atrasadas;
              adia[3] = response.data[i].alDia;
            }
            if (response.data[i].descripcion == "TOTAL") {
              this.absfTiempo[0] = response.data[i].fueraTiempo;
              this.abseTiempo[0] = response.data[i].enTiempo;
              this.absAtrasado[0] = response.data[i].atrasadas;
              this.absaDia[0] = response.data[i].alDia;
            }
          }

          this.chartOptions.chartEjecutivo.series[0].data = fTiempo;
          this.chartOptions.chartEjecutivo.series[1].data = eTiempo;
          this.chartOptions.chartEjecutivo.series[2].data = atrasado;
          this.chartOptions.chartEjecutivo.series[3].data = adia;

          dataValues.idUsuario = 0;

          axios
            .post(
              this.url + "mobysuite/dashboard?" + ((this.tokenUser == null || this.tokenUser == "") ? "uName=web&uPass=196f3c13338147a9dafacd5646fa0986" :
              "uToken="+this.tokenUser) + "&graph=1",
              dataValues
            )
            .then((response) => {
              var fTiempo = [0, 0, 0],
                eTiempo = [0, 0, 0],
                atrasado = [0, 0, 0],
                adia = [0, 0, 0];
              for (var i = 0; i < response.data.length; i += 1) {
                if (response.data[i].descripcion == "NO GESTIONADO") {
                  fTiempo[0] = response.data[i].fueraTiempo;
                  eTiempo[0] = response.data[i].enTiempo;
                  atrasado[0] = response.data[i].atrasadas;
                  adia[0] = response.data[i].alDia;
                }
                if (response.data[i].descripcion == "NO EFECTIVOS") {
                  fTiempo[1] = response.data[i].fueraTiempo;
                  eTiempo[1] = response.data[i].enTiempo;
                  atrasado[1] = response.data[i].atrasadas;
                  adia[1] = response.data[i].alDia;
                }
                if (response.data[i].descripcion == "EN PROCESO") {
                  fTiempo[2] = response.data[i].fueraTiempo;
                  eTiempo[2] = response.data[i].enTiempo;
                  atrasado[2] = response.data[i].atrasadas;
                  adia[2] = response.data[i].alDia;
                }
                if (response.data[i].descripcion == "CERRADO") {
                  fTiempo[3] = response.data[i].fueraTiempo;
                  eTiempo[3] = response.data[i].enTiempo;
                  atrasado[3] = response.data[i].atrasadas;
                  adia[3] = response.data[i].alDia;
                }
                if (response.data[i].descripcion == "TOTAL") {
                  this.absfTiempo[1] = response.data[i].fueraTiempo;
                  this.abseTiempo[1] = response.data[i].enTiempo;
                  this.absAtrasado[1] = response.data[i].atrasadas;
                  this.absaDia[1] = response.data[i].alDia;
                }
              }

              this.chartOptions.chartTodosEjecutivos.series[0].data = fTiempo;
              this.chartOptions.chartTodosEjecutivos.series[1].data = eTiempo;
              this.chartOptions.chartTodosEjecutivos.series[2].data = atrasado;
              this.chartOptions.chartTodosEjecutivos.series[3].data = adia;

              this.chartOptions.chartCompAbsoluto.series[0].data =
                this.absfTiempo;
              this.chartOptions.chartCompAbsoluto.series[1].data =
                this.abseTiempo;
              this.chartOptions.chartCompAbsoluto.series[2].data =
                this.absAtrasado;
              this.chartOptions.chartCompAbsoluto.series[3].data = this.absaDia;

              this.chartOptions.chartCompPorcentaje.series[0].data =
                this.absfTiempo;
              this.chartOptions.chartCompPorcentaje.series[1].data =
                this.abseTiempo;
              this.chartOptions.chartCompPorcentaje.series[2].data =
                this.absAtrasado;
              this.chartOptions.chartCompPorcentaje.series[3].data =
                this.absaDia;

              this.isLoading = false;
            })
            .catch((error) => {
              alert(
                "No fue posible cargar la información de la gráfica general: " +
                  error
              );
              console.log(error);
              this.isLoading = false;
            });
        })
        .catch((error) => {
          alert(
            "No fue posible cargar la información de la gráfica del usuario: " +
              error
          );
          console.log(error);
          this.isLoading = false;
        });
    },
    limpiar() {
      this.selectedUser = null;
      this.selectedProject = null;
      this.fechaInicio = null;
      this.fechaFin = null;

      this.chartOptions.chartEjecutivo.series[0].data = [0, 0, 0];
      this.chartOptions.chartEjecutivo.series[1].data = [0, 0, 0];
      this.chartOptions.chartEjecutivo.series[2].data = [0, 0, 0];
      this.chartOptions.chartEjecutivo.series[3].data = [0, 0, 0];

      this.chartOptions.chartTodosEjecutivos.series[0].data = [0, 0, 0];
      this.chartOptions.chartTodosEjecutivos.series[1].data = [0, 0, 0];
      this.chartOptions.chartTodosEjecutivos.series[2].data = [0, 0, 0];
      this.chartOptions.chartTodosEjecutivos.series[3].data = [0, 0, 0];

      this.chartOptions.chartCompAbsoluto.series[0].data = [0, 0];
      this.chartOptions.chartCompAbsoluto.series[1].data = [0, 0];
      this.chartOptions.chartCompAbsoluto.series[2].data = [0, 0];
      this.chartOptions.chartCompAbsoluto.series[3].data = [0, 0];

      this.chartOptions.chartCompPorcentaje.series[0].data = [0, 0];
      this.chartOptions.chartCompPorcentaje.series[1].data = [0, 0];
      this.chartOptions.chartCompPorcentaje.series[2].data = [0, 0];
      this.chartOptions.chartCompPorcentaje.series[3].data = [0, 0];
    },
    onCancel() {
      console.log("User cancelled the loader.");
    },
  },
  mounted() {
    axios
      .post(
        this.url + "mobysuite/dashboard?" + ((this.tokenUser == null || this.tokenUser == "") ? "uName=web&uPass=196f3c13338147a9dafacd5646fa0986" :
          "uToken="+this.tokenUser) + "&graph=0"
      )
      .then((response) => (this.users = response.data))
      .catch((error) => console.log(error));

    axios
      .get(
        this.url + "mobysuite/listProjects?" + ((this.tokenUser == null || this.tokenUser == "") ? "uName=web&uPass=196f3c13338147a9dafacd5646fa0986" :
          "uToken="+this.tokenUser)+"&dashboard_widget=true"
      )
      .then((response) => (this.projects[0].projectsInfo = response.data))
      .catch((error) => console.log(error));
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
moby-dashboard .line {
  text-align: center;
  display: flex;
  padding: 100px;
}

moby-dashboard .moby-bg {
  background-color: #5fa2dd;
  color: white;
  font-size: 12px;
  padding: 5px;
  margin-top: 0px;
  border: 1px solid #2a6191;
}

moby-dashboard .chart {
  top: 100px;
  height: auto;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

moby-dashboard .search {
  width: 100%;
  display: flex;
}

moby-dashboard .cart {
  border: 1px solid #5fa2dd;
  padding: 0px;
  bottom: 30px;
}

moby-dashboard .select {
  padding: 10px;
}

moby-dashboard h1 {
  background-color: #5fa2dd;
  color: white;
  font-size: 12px;
  padding: 5px;
  margin-top: 0px;
  border: 1px solid #5fa2dd;
}

moby-dashboard h2 {
  font-size: 12px;
  padding: 5px;
  margin-top: 13px;
  font-weight: normal;
}

moby-dashboard .select select,
moby-dashboard .select .dates {
  min-height: 40px;
  display: block;
  padding: 8px 5px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
  margin-bottom: 10px;
  padding-top: 2px;
  color: #35495e;
  width: 170px;
}

moby-dashboard {
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

moby-dashboard button {
  width: 70px;
  height: 35px;
  margin-left: 10px;
  margin-bottom: 15px;
}

moby-dashboard .moby-font-family {
  font-family: Helvetica, Arial, verdana, sans-serif;
}

</style>
