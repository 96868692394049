<template>
  <div id="app">
    <div id="grafico">
      <MobyDashboard :url="url" v-if="tipo==0" :tokenUser="tokenUser"></MobyDashboard>
      <HitosProyecto :url="url" v-else-if="tipo==1" :tokenUser="tokenUser" :proyectoId="proyectoId" :proyectoNombre="proyectoNombre" :fechaInicio="fechaInicio" :fechaFin="fechaFin" :idEmpresa="idEmpresa"></HitosProyecto>
      <Marketing :url="url" v-else-if="tipo==2" :tokenUser="tokenUser" :country="country"></Marketing>
      <div v-else>
          <h2> No se ha enviado el tipo de reporte solicitado (dashboard => 0, hitos del proyecto => 1, marketing => 2) </h2>
      </div>
    </div>
    
    <!--<div id="botones">
      <button>Gestion de Eficiencia de Ejecutivos</button>
    </div>-->
  </div>
</template>

<script>
import MobyDashboard from "./components/MobyDashboard.vue";
import HitosProyecto from "./components/HitosProyecto.vue";
import Marketing from "./components/Marketing.vue";

export default {
  name: 'Dashboard',
  components: {
    MobyDashboard,
    HitosProyecto,
    Marketing
  },
  props: {
    url: {type: String},
    tokenUser: {type: String},
    tipo: {
      type: Number,
      default: 0},
    proyectoId: {type: Number},
    proyectoNombre: {type: String},
    fechaInicio: {type: String},
    fechaFin: {type: String},
    idEmpresa: {type: Number},
    country: {
      type: String,
      default: 'CL' // 'CL' -> Chile, 'PE' -> Perú
    } 
  }
}
</script>

<style>
body {
  height: 100%;
}

moby-dashboard {
  height: 100%;
}
</style>
