<template>
  <div class="vld-parent">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :on-cancel="onCancel"
      :is-full-page="false"
      :color="'#5fa2dd'"
    ></loading>

    <div class="cart">
      <h1 class="moby-font-family">Filtro</h1>
      <div class="search">
        
        <div class="select">
          <multiselect
            v-model="hitoSeleccionado"
            :options="hitosDisponibles"
            :multiple="true"
            group-values="hitosInfo"
            group-label="hitosGroup"
            :group-select="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Seleccione un hito"
            label="nombreHito"
            track-by="nombreHito"
            :preselect-first="false"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }"
              ><span
                class="multiselect__single"
                v-if="values.length &amp;&amp; !isOpen"
                >{{ values.length }} opciones seleccionadas</span
              ></template
            >
          </multiselect>
        </div>


        <div class="select">
          <multiselect
            v-model="selectedProject"
            :options="projects"
            :multiple="true"
            group-values="projectsInfo"
            group-label="projectGroup"
            :group-select="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Seleccione un proyecto"
            label="nombreProyecto"
            track-by="nombreProyecto"
            :preselect-first="false"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }"
              ><span
                class="multiselect__single"
                v-if="values.length &amp;&amp; !isOpen"
                >{{ values.length }} opciones seleccionadas</span
              ></template
            >
          </multiselect>
        </div>

        <h2>Rango de fechas</h2>

        <div class="select">
          <input v-model="fechaInicio" type="date" class="dates" />
        </div>

        <div class="select">
          <input v-model="fechaFin" type="date" class="dates" />
        </div>
      </div>

      <button @click="buscar" class="moby-bg">Buscar</button>
      <button @click="limpiar" class="moby-bg">Limpiar</button>
    </div>

    <div class="cart">
      <h1 class="moby-font-family">Top</h1>
      <div class="modydash-cont-marketing">

        <div>
          <div class="mobydash-col">
            <highcharts :options="chartOptions.medioInformacion"></highcharts>
          </div>
        </div>

        <div>
          <div class="mobydash-col">
            <highcharts :options="chartOptions.tipoContacto" ></highcharts>
          </div>
        </div>

        <div class="div-table">
          <div class="modydash-text1"><span>Top 10 Medios</span></div>
          <div class="mobydash-col">
            <table class="table-medios">
              <thead>
                <tr>
                  <th class="table-medios-header">NOMBRE</th>
                  <th class="table-medios-header">CANTIDAD</th>
                  <th class="table-medios-header">PORCENTAJE</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="medioInformacion in mediosInformacion" v-bind:key="medioInformacion.nombre">
                  <td>{{medioInformacion.nombre}}</td>
                  <td class="medioCantidad">{{medioInformacion.cantidad}}</td>
                  <td class="medioPorcentaje">{{medioInformacion.porcentaje}} %</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="div-table">
          <div class="modydash-text1"><span>Top 10 Tipos de contacto</span></div>
          <div class="mobydash-col">
            <table class="table-medios">
              <thead>
                <tr>
                  <th class="table-medios-header">NOMBRE</th>
                  <th class="table-medios-header">CANTIDAD</th>
                  <th class="table-medios-header">PORCENTAJE</th>
                </tr>
              </thead>
              <tbody>                
                <tr v-for="tipoContacto in tiposContacto" v-bind:key="tipoContacto.nombre">
                  <td>{{tipoContacto.nombre}}</td>
                  <td class="medioCantidad">{{tipoContacto.cantidad}}</td>
                  <td class="medioPorcentaje">{{tipoContacto.porcentaje}} %</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import axios from "axios";
import Multiselect from "vue-multiselect";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

let dataValues = {
  hito: [],
  idProyecto: [],
  fechaInicio: "",
  fechaFin: "",
  tipoDato: "",
};

export default {
  name: "MobyDashboard",
  components: {
    highcharts: Chart,
    Multiselect,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      hitoSeleccionado: null,
      hitosDisponibles: [
        {
          hitosGroup: "Seleccionar/Deseleccionar todos",
          hitosInfo: [ 
            {nombreHito: "CONTACTOS"}, {nombreHito: "CONTACTOS UNICOS"}, {nombreHito: "SALA VENTAS"}, 
            {nombreHito: this.country === 'CL' ? "COTIZACIONES" :"PROFORMAS"}, 
            {nombreHito: this.country === 'CL' ? "COTIZACIONES UNICAS" : "PROFORMAS UNICAS"}, 
            {nombreHito: "LEADS UNICOS"},
            {nombreHito: this.country === 'CL' ? "RESERVAS" : "SEPARACIONES"}, 
            {nombreHito: this.country === 'CL' ? "PROMESAS" : "MINUTAS"}
            
          ],
        },
      ],
      mediosInformacion: [],
      tiposContacto: [],
      selectedProject: null,
      fechaInicio: null,
      fechaFin: null,
      users: [],
      projects: [
        {
          projectGroup: "Seleccionar/Deseleccionar todos",
          projectsInfo: [],
        },
      ],
      chartOptions: {
        medioInformacion: {
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
          },
          title: {
          text: 'Medios de información'
          },
          tooltip: {
            pointFormat: '<b>{point.percentage:.1f}%</b>'
          },
          accessibility: {
            point: {
              valueSuffix: '%'
            }
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: true,
                  pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>'
              },
              showInLegend: true
            }
          },
          series: [{
            name: 'Medio',
            colorByPoint: true,
            data: []
          }]
        },
        tipoContacto: {
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
          },
          title: {
          text: 'Tipo de contacto'
          },
          tooltip: {
            pointFormat: '<b>{point.percentage:.1f}%</b>'
          },
          accessibility: {
            point: {
              valueSuffix: '%'
            }
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: true,
                  pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>'
              },
              showInLegend: true
            }
          },
          series: [{
            name: 'Medio',
            colorByPoint: true,
            data: []
          }]
        }
      }, 
    };
  },
  props: {
    url: String,
    tokenUser: {type:String},
    country: {
      type: String
    } 
  },
  methods: {
    buscar() {
      dataValues.hito = [];
      dataValues.idProyecto = [];
      dataValues.fechaInicio = this.fechaInicio;
      dataValues.fechaFin = this.fechaFin;
      if (
        dataValues.hito == null ||
        dataValues.idProyecto == null ||
        dataValues.fechaInicio == null ||
        dataValues.fechaFin == null ||
        this.selectedProject == null ||
        this.hitoSeleccionado == null ||
        this.selectedProject.length == 0 || 
        this.hitoSeleccionado.length == 0
      ) {
        alert("Favor de llenar todos los campos");
        return;
      }
      var d1 = Date.parse(dataValues.fechaInicio);
      var d2 = Date.parse(dataValues.fechaFin);
      if (d1 > d2) {
        alert("Error! Las fechas no son Correctas");
        return;
      }

      for (var project of this.selectedProject) {
        dataValues.idProyecto.push(project.id);
      }

      //Reemplazar los valores de Perú a los correspondientes de CHILE (obligatorios en la búsqueda por la enumeración MobyDashboard.Tipo)
      for(let hitoSel of this.hitoSeleccionado) {
        dataValues.hito.push(hitoSel.nombreHito.replace(" ", "_")); //Para la enumeración
      }

      // Reemplazar por los nombres adecuados para Chile si el el moby de Perú
      if(this.country === 'PE'){
        for(let i = 0; i < dataValues.hito.length; i++){
          console.log("nombre hito: ");
          console.log(dataValues.hito[i]);
          switch(dataValues.hito[i]){
            case "PROFORMAS":
              dataValues.hito[i] = dataValues.hito[i].replace("PROFORMAS", "COTIZACIONES");
              break;
            case "SEPARACIONES":
              dataValues.hito[i] = dataValues.hito[i].replace("SEPARACIONES", "RESERVAS");
              break;
            case "MINUTAS":
              dataValues.hito[i] = dataValues.hito[i].replace("MINUTAS", "PROMESAS");
              break;
            case "PROFORMAS_UNICAS":
              dataValues.hito[i] = dataValues.hito[i].replace("PROFORMAS", "COTIZACIONES");
              break;
          }
        }
      }

      this.isLoading = true;
      
      // Cargar medios de información
      dataValues.tipoDato = "MEDIO_INFORMACION";
      axios
      .post(
        this.url + "mobysuite/dashboard?" + ((this.tokenUser == null || this.tokenUser == "") ? "uName=web&uPass=196f3c13338147a9dafacd5646fa0986" :
          "uToken="+this.tokenUser) + "&graph=3",dataValues
      )
      .then((response) => {
        // this.projects[0].projectsInfo = response.data
        var data = [];
        var dataMedios = [];
        var total = 0;
        for (var i = 0; i < response.data.length; i += 1) {
          // console.log("Medio: " + response.data[i].nombre + " - " + response.data[i].cantidad);
          // console.log("Cantidad: " + response.data[i].cantidad);
          data.push({name: response.data[i].nombre.toUpperCase(), y: response.data[i].cantidad});
          if(i < 10) {
            dataMedios.push({nombre: response.data[i].nombre.toUpperCase(), cantidad: response.data[i].cantidad, porcentaje: 0.0});
          }          

          total += response.data[i].cantidad;
        }
        this.chartOptions.medioInformacion.series[0].data = data;
        
        for(let i = 0; i < dataMedios.length; i++){
          dataMedios[i].porcentaje = (dataMedios[i].cantidad / total * 100).toFixed(2);
        }
        this.mediosInformacion = dataMedios;


    })
      .catch((error) => {
        console.log(error)
        this.isLoading = false;
    })

    

    /********************************************************************************************************* */
    dataValues.tipoDato = "TIPO_CONTACTO";
    axios
      .post(
        this.url + "mobysuite/dashboard?" + ((this.tokenUser == null || this.tokenUser == "") ? "uName=web&uPass=196f3c13338147a9dafacd5646fa0986" :
          "uToken="+this.tokenUser) + "&graph=3",dataValues
      )
      .then((response) => {
        var data = [];
        var dataTiposC = [];
        var total = 0;
        for (var i = 0; i < response.data.length; i += 1) {
          data.push({name: response.data[i].nombre.toUpperCase(), y: response.data[i].cantidad});
          if(i < 10) {
            dataTiposC.push({nombre: response.data[i].nombre.toUpperCase(), cantidad: response.data[i].cantidad, porcentaje: 0.0});
          } 
          total += response.data[i].cantidad;
        }
        this.chartOptions.tipoContacto.series[0].data = data;

        for(let i = 0; i < dataTiposC.length; i++){
          dataTiposC[i].porcentaje = (dataTiposC[i].cantidad / total * 100).toFixed(2);
        }
        this.tiposContacto = dataTiposC;

        this.isLoading = false;
    })
      .catch((error) => {
        console.log(error)
        this.isLoading = false;
    })

    },
    limpiar() {
      this.hitoUsuario = null;
      this.selectedProject = null;
      this.fechaInicio = null;
      this.fechaFin = null;
      this.hitoSeleccionado = null;
      this.chartOptions.medioInformacion.series[0].data = [];
      this.chartOptions.tipoContacto.series[0].data = [];
      this.mediosInformacion = [];
      this.tiposContacto = [];
    },
    onCancel() {
      console.log("User cancelled the loader.");
    },
  },
  mounted() {
    // Cargar fechas de prueba...
    // this.fechaInicio = '2010-07-21';
    // this.fechaFin = '2022-07-21';
    
    axios
      .get(
        this.url + "mobysuite/listProjects?" + ((this.tokenUser == null || this.tokenUser == "") ? "uName=web&uPass=196f3c13338147a9dafacd5646fa0986" :
          "uToken="+this.tokenUser) + "&dashboard_widget=true"
      )
      .then((response) => (this.projects[0].projectsInfo = response.data))
      .catch((error) => console.log(error));

      this.hitoSeleccionado = [];
      this.hitoSeleccionado.push({nombreHito: "CONTACTOS UNICOS"});
      this.hitoSeleccionado.push({nombreHito: this.country === 'CL' ? "COTIZACIONES UNICAS" : "PROFORMAS UNICAS"});
  },
};

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
@import '../../assets/main.css';
</style>
