<template>
  <div class="vld-parent">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :on-cancel="onCancel"
      :is-full-page="false"
      :color="'#5fa2dd'"
    ></loading>

    <div class="cart">
      <h1 class="moby-font-family">Gráfico de hitos del proyecto</h1>
      <div class="modydash-cont-hitos">
        <div>
          <div class="mobydash-col">
            <highcharts :options="chartOptions"></highcharts>
          </div>
          <div class="modydash-footer-container-hitos">
            <div class="modydash-text3">Hitos del proyecto</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
exporting(Highcharts);



let dataValues = {
  idProyecto: 0,
  fechaInicio: "",
  fechaFin: "",
  idEmpresa: 0
};


export default {
  name: "HitosProyecto",
  components: {
    highcharts: Chart,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      selectedUser: null,
      selectedProject: null,
      users: [],
      projects: [
        {
          projectGroup: "Seleccionar/Deseleccionar todos",
          projectsInfo: [],
        },
      ],
      chartOptions: {
          exporting: {
            buttons: {
              contextButton: {
                menuItems: ['downloadPNG', 'downloadJPEG'],
              },
            },
          },
        chart: {
            type: 'line',
            // height: (9 / 20 * 100) + '%',
            zoomType: 'y'
        },
        title: {
            text: 'Proyecto: '
        },
        subtitle: {
            text: 'Fecha: '
        },
        xAxis: {
            categories: ['Ene-20', 'Feb-20', 'Mar-20', 'Abr-20', 'May-20', 'Jun-20', 'Jul-20', 'Ago-20', 'Sep-20', 'Oct-20', 'Nov-20', 'Dec-20']
        },
        yAxis: [{
            title: {
                text: 'Cantidad por mes (escala pequeña)'
            }
        },
        {
            title: {
                text: 'Cantidad por mes (escala grande)'
            },
            opposite: true
        },
        ],
        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                },
                enableMouseTracking: true
            }
        },
        tooltip: {
          enabled: true
        },
        series: [{
            name: '',
            data: []
        }, {
            name: '',
            data: []
        }, {
            name: '',
            data: []
        }, {
            name: '',
            data: []
        }, {
            name: '',
            data: []
        }, {
            name: '',
            data: []
        }, {
            name: '',
            data: []
        }, {
            name: '',
            data: []
        }, {
            name: '',
            data: []
        }, {
            name: '',
            data: []
        }, {
            name: '',
            data: []
        }, {
            name: '',
            data: []
        }
        ]
      }
    }
  },

  props: {
    url: {String},
    proyectoId: {Number},
    proyectoNombre: {String},
    fechaInicio: {String},
    fechaFin: {String},
    idEmpresa: {Number},
    tokenUser: {String}
  },

  methods: {
    onCancel() {
      console.log("User cancelled the loader.");
    },
  },
  
  mounted() {
    //dataValues.idUsuario = 53;
    dataValues.idProyecto = this.proyectoId;
    dataValues.fechaInicio = this.fechaInicio;    
    dataValues.fechaFin = this.fechaFin;
    dataValues.idEmpresa = this.idEmpresa;

// CARGA DE DATOS
    this.isLoading = true;
    axios
        .post(
          this.url + "mobysuite/dashboard?" + ((this.tokenUser == null || this.tokenUser == "") ? "uName=web&uPass=196f3c13338147a9dafacd5646fa0986" :
          "uToken="+this.tokenUser) + "&graph=2",
          dataValues
        )
        .then((response) => {
          // console.log(response);
          var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
          var hitos = ["Contactos", "Cotizaciones", "Contactos Únicos", "Cotizaciones Únicas", 
            "Cotizaciones Web", "Cotizaciones Web Únicas",
            "Visitas Sala De Ventas", "Leads Únicos", "Reservas", "Promesas", "Escrituras", "Reversas"];
          var hitosActivos = ["Cotizaciones", "Reservas", "Promesas", "Escrituras"];
          
          var mesInicio  = parseInt(dataValues.fechaInicio.substring(5,7)) - 1;
          var anioInicio = parseInt(dataValues.fechaInicio.substring(0,4));
          var mesFin = parseInt(dataValues.fechaFin.substring(5,7)) - 1;
          var anioFin = parseInt(dataValues.fechaFin.substring(0,4));
          var mesesCantidad = ((anioFin * 12) + mesFin) - ((anioInicio * 12) + mesInicio) + 1;
          var etiquetasMeses = [];
          this.chartOptions.subtitle.text = meses[mesInicio] + '-' + anioInicio + " a " + meses[mesFin] + "-" + anioFin;

          for(let j = 0; j < mesesCantidad; j++) {
            etiquetasMeses.push(meses[mesInicio] + " - " + anioInicio);
            mesInicio++;
            if(mesInicio == 12) {
              mesInicio = 0;
              anioInicio++;
            }
          }
          this.chartOptions.xAxis.categories = etiquetasMeses;

          // recolectar los datos para el gráfico
          var dataForGraph = [[],[],[],[],[],[],[],[],[], [], [], []];
          for (let i = 0; i < response.data.length; i += 1) {
            for(let k = 0; k < hitos.length; k++) {
              if(response.data[i].concepto == hitos[k]) {
                dataForGraph[k].push(response.data[i].valor);
                break;
              }
            } 
          }

          // agregar los datos al gráfico
          for(let i = 0; i < hitos.length; i++) {
            this.chartOptions.series[i].data = dataForGraph[i];
            this.chartOptions.series[i].name = hitos[i];

            // Indicar el eje y a utilizar ( 1 para el derecho -> valores grandes)
            if(hitos[i] == 'Cotizaciones' || hitos[i] == 'Cotizaciones Únicas' || hitos[i] == 'Cotizaciones Web'){
              this.chartOptions.series[i].yAxis = 1;
            }
          }

          // ocultar/mostrar hitos específicos
          var mostrar = false;
          for(let i = 0; i < hitos.length; i++) {
            for(let j = 0; j < hitosActivos.length; j++) {
              if(hitos[i] == hitosActivos[j]){
                mostrar = true;
                break;
              }
            }

            if(!mostrar) {
              this.chartOptions.series[i].visible = false;
            }
            mostrar = false;
          }
          
          this.chartOptions.title.text = "Proyecto " + this.proyectoNombre;
          
          this.isLoading = false;
        })
        .catch((error) => {
          alert(
            "No fue posible cargar la información de la gráfica del usuario: " +
              error
          );
          console.log(error);
          this.isLoading = false;
        });
  
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
moby-dashboard .line {
  text-align: center;
  display: flex;
  padding: 100px;
}

moby-dashboard .moby-bg {
  background-color: #5fa2dd;
  color: white;
  font-size: 12px;
  padding: 5px;
  margin-top: 0px;
  border: 1px solid #2a6191;
}

moby-dashboard .chart {
  top: 100px;
  height: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

moby-dashboard .search {
  width: 100%;
  display: flex;
}

moby-dashboard .cart {
  border: 1px solid #5fa2dd;
  padding: 0px;
  bottom: 30px;
  height: 100%;
}

moby-dashboard .select {
  padding: 10px;
}

moby-dashboard h1 {
  background-color: #5fa2dd;
  color: white;
  font-size: 12px;
  padding: 5px;
  margin-top: 0px;
  border: 1px solid #5fa2dd;
}

moby-dashboard h2 {
  font-size: 12px;
  padding: 5px;
  margin-top: 13px;
  font-weight: normal;
}

moby-dashboard .select select,
moby-dashboard .select .dates {
  min-height: 40px;
  display: block;
  padding: 8px 5px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
  margin-bottom: 10px;
  padding-top: 2px;
  color: #35495e;
  width: 170px;
}

moby-dashboard {
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    height: 100%;
}

moby-dashboard button {
  width: 70px;
  height: 35px;
  margin-left: 10px;
  margin-bottom: 15px;
}

modydash-cont-hitos {
  width: 100%;
  height: 100%;
}

moby-dashboard .moby-font-family {
  font-family: Helvetica, Arial, verdana, sans-serif;
}

</style>
